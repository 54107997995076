import firebase from 'firebase/app'
import 'firebase/database'
const firebaseConfig = {
    apiKey: "AIzaSyD8wM6ZhoQPknv8379eE-JU18oXTKTT4rc",
    authDomain: "sfc-video-b88ef.firebaseapp.com",
    databaseURL: "https://sfc-video-b88ef.firebaseio.com",
    projectId: "sfc-video-b88ef",
    storageBucket: "gs://sfc-video-b88ef.appspot.com",
    messagingSenderId: "574577102720",
    appId: "1:574577102720:web:834c178cad30fe2e87bb65",
    measurementId: "G-GTN6D28267"
  };

var firebaseInit = firebase.initializeApp(firebaseConfig);

export const db = firebaseInit.database()

//export const st = firebaseInit.storage()

//export const an = firebaseInit.analytics()
