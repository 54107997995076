<template>
  <div class="bg-dark">
    <b-navbar toggleable="lg" type="dark" variant="dark" style="height:56px;" class="container">
      <b-navbar-brand to="/home">
        <img
          src="@/assets/img/gef-logo.png"
          height="30"
          class="d-inline-block mr-3"
          alt="GEF Logo"
        />
        <span>Scouting</span>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse v-show="user" id="nav-collapse" is-nav class="bg-dark px-2">
        <b-navbar-nav>
          <b-nav-item v-if="$store.state.currentScoutRole && $store.state.currentScoutRole!='SCOUT'" :to="{ name:'ScoutingSearch'}">Joueurs</b-nav-item>
          <b-nav-item v-if="$store.state.currentUser && $store.state.currentUser.role=='ADMIN'" :to="{ name:'UsersList'}">Membres</b-nav-item>
          <!--v-if="($store.state.currentUser.role=='ADMIN' || $store.state.currentUser.role=='COACH') && $store.state.currentUser.isScout"-->
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown v-show="user" right>
            <!-- Using 'button-content' slot -->
            <template v-slot:button-content>
              <em>{{ userName}}</em>
            </template>
            <b-dropdown-item v-show="user" @click="signoutButtonPressed">Déconnexion</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item v-show="!user">
            <router-link to="/login">Connexion</router-link>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import firebase from "firebase";
export default {
  data() {
    return {
      user: null,
    };
  },
  created() {
    firebase.auth().onAuthStateChanged(user => {
      this.user = user;
    });
  },
  mounted() {
  },
  computed: {
    userName(){
      if(this.user){
        return this.user.displayName;
      }
      return "User";
    },
    
  },
  methods: {
    signoutButtonPressed(e) {
      e.stopPropagation();
      firebase.auth().signOut();
      this.$router.push({ name: "Login" });
    },
    openFullgameVideo(){
      var a = document.createElement("a");
        a.setAttribute('href', this.gameVideo.videoUrl);
        a.setAttribute('download','');
        a.setAttribute('target', '_blank');
        a.click();
    }
  }
};
</script>
<style scoped>
#nav-collapse{
  z-index:999;
}
</style>

