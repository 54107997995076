<template>
  <section>
    <navigation></navigation>
    <div class="container mt-3">
      <b-form-group label="Rechercher : " label-size="lg" label-cols-sm="2" label-for="filterInput">
        <b-input-group size="lg">
          <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Joueur, club, année,..." size="lg"></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
              <b-icon icon="backspace-fill"></b-icon>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group label="Depuis :" label-cols-sm="2" label-for="statusInput">
        <b-button-group size="sm" class="mt-1" >
          <b-button v-for="status in optionsTimeFilter" id="statusInput" v-bind:key="status.key" variant="secondary" @click="timeAgoValue = status.value">{{status.label}}</b-button>
          <b-button @click="timeAgoValue=null"><b-icon icon="x"/></b-button>
        </b-button-group>
      </b-form-group>
      <div class="my-2">{{ getObservationsWithPlayerInfo.length }} observations</div>
      <!--<b-button @click="csvExport(getObservationsWithPlayerInfo)" size="sm" class="my-1">Exporter (.csv)&nbsp;<b-icon icon="box-arrow-up-right" /> </b-button>-->
      <jsonExcel
          :data="getObservationsWithPlayerInfo"
          :fields="fieldsToExport"
          name="GEF Scouting Export.xls">
          <b-button size="sm">Exporter (.xls)&nbsp;<b-icon icon="box-arrow-up-right" /></b-button>
      </jsonExcel>
      <b-table
        show-empty
        small
        :items="getObservationsWithPlayerInfo"
        :fields="fields"
        :filter="filter"
        :filterIncludedFields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
        striped
        hover
      >
        <template v-slot:cell(actions)="row">
          <router-link :to="{ name: 'ScoutingPlayerProfile', params: {scoutingPlayerId: row.item.playerId} }">
            <b-icon icon="eye" class="rounded bg-light p-1" variant="dark" font-scale="1.5"></b-icon>
          </router-link>
          <router-link :to="{ name: 'ScoutingPlayerEdit', params: {scoutingPlayerId:row.item.playerId} }">
            <b-icon icon="pencil" class="rounded bg-light p-1" variant="dark" font-scale="1.5"></b-icon>
          </router-link>
          <b-icon icon="trash" class="rounded bg-danger p-1" variant="light" font-scale="1.5" v-if="$store.state.currentScoutRole=='ADMIN'" @click="deleteObservation(row.item)"></b-icon>
        </template>

        <template v-slot:cell(lastname)="row">{{row.item.player.lastname}}</template>
        <template v-slot:cell(firstname)="row">{{row.item.player.firstname}}</template>
        <template v-slot:cell(position)="row">
          <span v-if="row.item.player.positionList">{{row.item.player.positionList.join(', ')}}</span>
          <span v-if="row.item.player.position && !row.item.player.positionList">{{row.item.player.position}}</span>
        </template>
        <template v-slot:cell(promo)="row">{{row.item.player.promo}}</template>
        <template v-slot:cell(status)="row">{{row.item.player.status}}</template>
        <template v-slot:cell(createdAt)="row">{{ moment.utc(row.item.createdAt).format('DD.MM.YY HH:mm') }}</template>
        <!--<template v-slot:cell(statusEnvoi)="row">
          <b-icon :icon="!row.item.statusEnvoi || row.item.statusEnvoi != 'treated' ? 'eye' : 'check'" class="rounded bg-dark p-1"
                  :variant="!row.item.statusEnvoi || row.item.statusEnvoi == 'new' ? 'warning' : 'success'"
                  font-scale="1.5"
                  @click="changeStatus(row.item)"></b-icon> 
        </template>-->
        </b-table>
    </div>
  </section>
</template>

<script>
import { db } from "@/db";
import navigation from "@/components/NavBar.vue";
import jsonExcel from 'vue-json-excel';

export default {
  data() {
    return {
      observations: [],
      scoutingPlayers:[],
      playersObservations: [],
      fields: [
        { key: "actions", label: "Actions" },
        { key: "lastname", label: "Nom", sortable: true },
        { key: "firstname", label: "Prénom", sortable: true },
        { key: "position", label: "Position", sortable: true },
        { key: "promo", label: "Promo", sortable: true },
        { key: "status", label: "Statut ", sortable: true },
        //{ key: "club", label: "Club", sortable: true },
        //{ key: "strongfoot", label: "Pied fort", sortable: true },
        //{ key: "nationality", label: "Nationalité", sortable: true },
        { key: "author", label: "Auteur ", sortable: true },
        { key: "createdAt", label: "Date ", sortable: true },
      ],
      fieldsToExport:
        {
        'Nom':'player.lastname',
        'Prénom':'player.firstname',
        'Club':'player.club',
        'Nationalité':'player.nationality',
        'Promo':'player.promo',
        'Position':'player.position',
        'Club':'player.club',
        'Zone':'player.area',
        /*'T': {field:'player.technique',callback: (value) =>{return value.reduce((total, next) => total + next.age, 0) / value.length;}},
        'I': {field:'player.tactical',callback: (value) =>{return value.reduce((total, next) => total + next.age, 0) / value.length;}},
        'P': {field:'player.mental',callback: (value) =>{return value.reduce((total, next) => total + next.age, 0) / value.length;}},
        'S': {field:'player.condition',callback: (value) =>{return value.reduce((total, next) => total + next.age, 0) / value.length;}},*/
        'Auteur': 'author', 
        'Date': {field:'createdAt',callback: (value) =>{return this.moment.utc(value).format('DD.MM.YY')}},
      },
      labels: {
        author: 'Auteur',
        createdAt: 'Date',
        player:'Joueur'
      },
      totalRows: 1,
      sortBy: "createdAt",
      sortDesc: true,
      sortDirection: "desc",
      filter: null,
      filterOn: [],
      optionsTimeFilter:[{label:'Dernier jour', value: 'lastDay'},{label:'Dernière semaine', value: 'lastWeek'},{label:'Dernier mois', value: 'lastMonth'}],
      timeAgoValue:null,
    };
  },
  components: {
    navigation,
    jsonExcel
  },
  firebase() {
    return {
      scoutingPlayers: db.ref("scouting-players"),
      playersObservations: db.ref("scouting-observations"),
    };
  },
  created() {
    var rolesAccepted = ['ADMIN', 'TOPMANAGER', 'CLUBMANAGER'];
    if(!this.$store.state.currentUser || !this.$store.state.currentScoutRole || !rolesAccepted.includes(this.$store.state.currentScoutRole)){
      this.$router.push({ name: 'Home'});
    }
    /*if(this.$store.state.currentScoutRole=='ADMIN' || this.$store.state.currentScoutRole=='TOPMANAGER'){
      this.fields.push({ key: "statusEnvoi", label: "Envoi ", sortable: true });
    }*/
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      //this.currentPage = 1
    },
    changeStatus(observation){
      if(!observation.statusEnvoi || observation.statusEnvoi=='new'){
        observation.statusEnvoi = 'viewed';
      } else if(observation.statusEnvoi=='viewed'){
        observation.statusEnvoi = 'treated';
      } else if(observation.statusEnvoi=='treated'){
        observation.statusEnvoi = 'new';
      } 
      return db
            .ref("scouting-observations")
            .child(observation['.key'])
            .set(observation);
    },
    deleteObservation(observation){
      //console.log("observation");
      //console.log({ observation });
      //console.log(observation[".key"]);
      if (this.$store.state.currentScoutRole=='ADMIN' && confirm("Veuillez confirmer la suppression de l'observation : "+observation.player.lastname)) {
        return db
          .ref("scouting-observations")
          .child(observation[".key"])
          .remove();
      }
      return;
    },
    csvExport: function (arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map((item) => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
  },
  computed: {
    getObservationsWithPlayerInfo(){
      var observations = [];
      if(!this.scoutingPlayers || this.scoutingPlayers.length==0){
        return [];
      }
      if(this.timeAgoValue){
        var createdAtBackInTime = 0;
        switch (this.timeAgoValue) {
          case 'lastDay':
            createdAtBackInTime = Date.now() - (1*24*3600*1000);
            break;
          case 'lastWeek':
            createdAtBackInTime = Date.now() - (5*24*3600*1000);
            break;
          case 'lastMonth':
            createdAtBackInTime = Date.now() - (30*24*3600*1000);
            break;
          default:
            createdAtBackInTime = 0;
        }
        this.playersObservations.forEach(plObs => {
            if(plObs.createdAt<createdAtBackInTime){
              return;
            }
            plObs.player = this.scoutingPlayers.filter(pl => pl.childKey==plObs.playerId)[0];
            observations.push(plObs);
          }); 
        return observations;
      }
      //console.log(this.scoutingPlayers);
      this.playersObservations.forEach(plObs => {
        plObs.player = this.scoutingPlayers.filter(pl => pl['.key']==plObs.playerId)[0];
        //console.log(plObs);
        observations.push(plObs);
      }); 
      console.log(observations);
      return observations;
    }
  }
};
</script>
<style scoped>
</style>

