<template>
  <div class="jumbotron py-4">
    <div class="container mt-4 text-white d-flex flex-row align-items-center justify-content-between flex-wrap">
      <div>
        <div class="pageName">{{ pageName}}</div>
      </div>
      <div class="pageButtons d-flex align-items-center">
        
        <b-dropdown id="dropdown-form" right text="Filtrer" ref="dropdown" class="my-2 mx-1" variant="outline-light" v-if="filterText">
          <template #button-content>
          <b-icon icon="funnel-fill"/>&nbsp;Filtrer
          </template>
          <b-dropdown-form style="width: 300px;">
            <b-form-group label="" label-for="filterInput">
              <b-input-group>
                <b-form-input v-model="filter" type="search" id="filterInput" :placeholder="filterText"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    <b-icon icon="backspace-fill"></b-icon>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-button-group size="sm" class="mt-1" v-if="filterButtons && typeof(filterButtons[0])=='object'">
              <b-button v-for="btn in filterButtons" v-bind:key="btn.key" variant="secondary" @click="filter = btn.value">{{btn.label}}</b-button>
            </b-button-group>
            <b-button-group size="sm" class="mt-1" v-if="filterButtons && typeof(filterButtons[0])=='string'">
              <b-button v-for="btn in filterButtons" v-bind:key="btn.key" variant="secondary" @click="filter = btn">{{btn}}</b-button>
            </b-button-group>
          </b-dropdown-form>
        </b-dropdown> 
        <b-button :to="{name:btn.link}" class="my-2 mx-1" v-for="btn in pageButtons" :key="btn.id" variant="light">
          <b-icon :icon="btn.icon"></b-icon>&nbsp;{{btn.text}}
        </b-button>     
      </div>
    </div>

  </div>
  
</template>
<script>
export default {
  data() {
    return {
      pageButtons:[], 
      filterText:null,
      filter:null,
      filterButtons:null,
    };
  },
  props: ["teamName", "pageName", "pageButtons", "filterText","filterButtons"],
  watch: {
    filter(filter){
      this.$emit('setNewFilter', filter);
    }
  },
  methods: {
    
  }
};
</script>
<style scoped>
.jumbotron {
  min-height: 25vh;
  background-color: #69c5f0;
  background: url("../assets/img/bg-jumbotron.svg") right center ;
  background-size: cover;
  border-radius: 0;
}
.pageName{
  font-size: 2rem;
  font-weight: 600;
  /*line-height: 1.2;
  position: absolute;
  left: 2rem;
  top: 0.5rem;
  letter-spacing: 2px;*/
}
.teamName{
  font-size: 1.5rem;
  font-weight: 600;

}

.is-animated {
  /*font-family: 'Helvetica Neue';
  font-weight: bold;*/
  padding: 0;
  position: relative;
}
.is-animated span {
  /*display: block;*/
  padding-right:  10px;
  /*width: 200px;*/
}
.pageName{
  font-size: 300%;
  border-right: 2px solid #fff;
  width: 0;
  overflow: hidden;
  white-space: nowrap;
  -webkit-animation-name: expandProperty;
  -webkit-animation-delay: .5s;
  -webkit-animation-duration: .8s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in;
}
.pageName{
  border-right: none;
  text-align: left;
}
.teamName{
  opacity: 1;
  animation: fadeIn linear 2s;
  -webkit-animation: fadeIn linear 2s;
  -moz-animation: fadeIn linear 2s;
  -o-animation: fadeIn linear 2s;
  -ms-animation: fadeIn linear 2s;

}
@-webkit-keyframes expandProperty {
  from {
    width: 0px;
  }
  to {
    width: 396px;
  }
}
@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  50% {opacity:0;}
  100% {opacity:1;} 
}
@media only screen and (max-width: 1026px) {
    .pageName {
      font-size: 1rem;
      font-weight: bold;
      width: auto;
      font-size: 200%;
      animation: none;
      -webkit-animation: none;
    }
    .teamName {
        font-size: 100%;
    }
}
</style>