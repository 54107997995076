<template>
  <section>
    <navigation></navigation>
    <div class="container mt-3 d-flex flex-column">
      <b-card bg-variant="light">
        <b-form-group
          label-cols-lg="3"
          label="Joueuse"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
        >
          <b-form-group
            label="Nom :"
            label-for="player-name"
            label-cols-sm="3"
            label-align-sm="right"
          >
          <vue-autosuggest
              v-model="query"
              :suggestions="filteredOptions"
              :input-props="{id:'player-name', placeholder:' '}"
              @focus="focusMe"
              @click="clickHandler"
              @input="onInputChange"
              @selected="onSelected"
              :get-suggestion-value="getSuggestionValue"
          >  
            <template slot-scope="{suggestion}">
              <span class="my-suggestion-item">{{suggestion.item.lastname}} {{suggestion.item.firstname}}</span>
            </template>
          </vue-autosuggest>
          <!--
            <input id="player-name" class="form-control" type="text" v-model="newPlayer.lastname"
            @keydown.enter = 'enter'
            @keydown.down = 'down'
            @keydown.up = 'up'
            @input = 'change'
              />
            <ul style="width:100%">
                <li v-for="(suggestion, $index) in matches"
                    v-bind:class="{'active': isActive($index)}"
                    v-bind:key="$index"
                    @click="suggestionClick($index)"
                >
                    <a href="#">{{ suggestion.firstname }} {{ suggestion.lastname }}</a>
                </li>
            </ul>-->
          </b-form-group>

          <b-form-group
            label="Prénom :"
            label-for="nested-city"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-input id="nested-city" v-model="newPlayer.firstname"></b-form-input>
          </b-form-group>

          <b-form-group
            label="Position :"
            label-for="nested-state"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-checkbox-group
                id="btn-radios-3"
                v-model="newPlayer.positionList"
                :options="optionsPositions"
                button-variant="outline-secondary"
                name="radio-btn-outline"
                buttons
              ></b-form-checkbox-group>
          </b-form-group>

          <b-form-group
            label="Année naissance :"
            label-for="nested-country"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-select v-model="newPlayer.promo" :options="optionsPromos" :state="isPromoValid"></b-form-select>
          </b-form-group>

          <b-form-group
            label="Club :"
            label-for="nested-state"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-input id="nested-state" v-model="newPlayer.club"></b-form-input>
          </b-form-group>

          <b-form-group
            label="Zone :"
            label-for="nested-state"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-radio-group
                id="btn-radios-3"
                v-model="newPlayer.area"
                :options="optionsArea"
                button-variant="outline-secondary"
                name="radio-btn-outline"
                buttons
              ></b-form-radio-group>
          </b-form-group>

          <b-form-group
            label="Statut :"
            label-for="nested-state"
            label-cols-sm="3"
            label-align-sm="right"
          >
          <b-form-radio-group
                id="btn-radios-3"
                v-model="newPlayer.status"
                :options="optionsStatus"
                button-variant="outline-secondary"
                name="radio-btn-outline"
                buttons
              ></b-form-radio-group>
          </b-form-group>

          <b-form-group
            label="Pied fort :"
            label-for="nested-state"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-radio-group
                id="btn-radios-2"
                v-model="newPlayer.strongfoot"
                :options="optionsStrongfoot"
                button-variant="outline-secondary"
                name="radio-btn-outline"
                buttons
              ></b-form-radio-group>
          </b-form-group>
          
          <b-form-group
            label="Nationalité :"
            label-for="nested-country"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-input id="nested-state" v-model="newPlayer.nationality"></b-form-input>
          </b-form-group>
        </b-form-group>
      </b-card>
      
      <b-card bg-variant="light" class="mt-3">
        <b-form-group
          label-cols-lg="3"
          label="Appréciation globale"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
        >
        <b-form-group
            label="Match observé :"
            label-for="nested-game"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-input id="nested-game" v-model="newPlayer.observation.game" placeholder="P. ex : Portugal - Angleterre"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Note :"
            label-for="nested-street"
            label-cols-sm="3"
            label-align-sm="right"
          > 
            <b-form-input  v-model="newPlayer.observation.globalNote" min="0.5" max="4" step="0.5" type="range" />
            <b-form-rating v-model="newPlayer.observation.globalNote" show-value readonly variant="dark" precision="1" stars="4"></b-form-rating>
          </b-form-group>
        </b-form-group>
      </b-card>
      
      <b-card bg-variant="light" class="mt-3">
        <b-form-group
          label-cols-lg="3"
          label="Tactique/Intelligence"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
        >
          <b-form-group
            label="Vision du jeu :"
            label-for="nested-street"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-rating v-model="newPlayer.observation.tactical.vision" show-value show-clear stars="4" variant="success"></b-form-rating>
          </b-form-group>

          <b-form-group
            label="Prise d'information :"
            label-for="nested-city"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-rating v-model="newPlayer.observation.tactical.lecture" show-value show-clear stars="4" variant="success"></b-form-rating>
          </b-form-group>

          <b-form-group
            label="Jeu sans ballon :"
            label-for="nested-state"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-rating v-model="newPlayer.observation.tactical.jeuSansBallon" show-value show-clear stars="4" variant="success"></b-form-rating>
          </b-form-group>

          <b-form-group
            label="Anticipation :"
            label-for="nested-country"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-rating v-model="newPlayer.observation.tactical.anticipation" show-value show-clear stars="4" variant="success"></b-form-rating>
          </b-form-group>

          <b-form-group
            label="Orientation du corps :"
            label-for="nested-country"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-rating v-model="newPlayer.observation.tactical.orientationCorps" show-value show-clear stars="4" variant="success"></b-form-rating>
          </b-form-group>
          <b-form-group
            label="Duels :"
            label-for="nested-country"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-rating v-model="newPlayer.observation.tactical.duels" show-value show-clear stars="4" variant="success"></b-form-rating>
          </b-form-group>
        </b-form-group>
      </b-card>
      <b-card bg-variant="light" class="mt-3">
        <b-form-group
          label-cols-lg="3"
          label="Condition physique"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
        >
          <b-form-group
            label="Vitesse :"
            label-for="nested-country"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-rating v-model="newPlayer.observation.condition.vitesse" show-value show-clear stars="4" variant="warning"></b-form-rating>
          </b-form-group>
          <b-form-group
            label="Tonicité/Explosivité :"
            label-for="nested-country"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-rating v-model="newPlayer.observation.condition.tonicite" show-value show-clear stars="4" variant="warning"></b-form-rating>
          </b-form-group>
          <b-form-group
            label="Vivacité :"
            label-for="nested-country"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-rating v-model="newPlayer.observation.condition.vivacite" show-value show-clear stars="4" variant="warning"></b-form-rating>
          </b-form-group>
          <b-form-group
            label="Volume du jeu :"
            label-for="nested-country"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-rating v-model="newPlayer.observation.condition.volume" show-value show-clear stars="4" variant="warning"></b-form-rating>
          </b-form-group>
          <b-form-group
            label="Puissance :"
            label-for="nested-country"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-rating v-model="newPlayer.observation.condition.puissance" show-value show-clear stars="4" variant="warning"></b-form-rating>
          </b-form-group>

        </b-form-group>
      </b-card>
      <b-card bg-variant="light" class="mt-3">
        <b-form-group
          label-cols-lg="3"
          label="Technique"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
        >
          <b-form-group
            label="Vitesse d'éxécution :"
            label-for="nested-country"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-rating v-model="newPlayer.observation.technique.vitesseExecution" show-value show-clear stars="4" variant="primary"></b-form-rating>
          </b-form-group>
          <b-form-group
            label="Dribbles :"
            label-for="nested-country"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-rating v-model="newPlayer.observation.technique.dribbles" show-value show-clear stars="4" variant="primary"></b-form-rating>
          </b-form-group>
          <b-form-group
            label="Tir :"
            label-for="nested-country"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-rating v-model="newPlayer.observation.technique.frappe" show-value show-clear stars="4" variant="primary"></b-form-rating>
          </b-form-group>
          <b-form-group
            label="Technique aérienne :"
            label-for="nested-country"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-rating v-model="newPlayer.observation.technique.jeuDeTete" show-value show-clear stars="4" variant="primary"></b-form-rating>
          </b-form-group>
          <b-form-group
            label="Qualité de passe :"
            label-for="nested-country"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-rating v-model="newPlayer.observation.technique.passe" show-value show-clear stars="4" variant="primary"></b-form-rating>
          </b-form-group>
          <b-form-group
            label="Prise de balle/Contrôle :"
            label-for="nested-country"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-rating v-model="newPlayer.observation.technique.priseDeBalle" show-value show-clear stars="4" variant="primary"></b-form-rating>
          </b-form-group>
        </b-form-group>
      </b-card>
      <b-card bg-variant="light" class="mt-3 mb-3">
        <b-form-group
          label-cols-lg="3"
          label="Mental"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
        >
          <b-form-group
            label="Gestion des émotions :"
            label-for="nested-country"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-rating v-model="newPlayer.observation.mental.emotions" show-value show-clear stars="4" variant="danger"></b-form-rating>
          </b-form-group>
          <b-form-group
            label="Détermination :"
            label-for="nested-country"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-rating v-model="newPlayer.observation.mental.determination" show-value show-clear stars="4" variant="danger"></b-form-rating>
          </b-form-group>
          <b-form-group
            label="Langage corporel :"
            label-for="nested-country"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-rating v-model="newPlayer.observation.mental.attitude" show-value show-clear stars="4" variant="danger"></b-form-rating>
          </b-form-group>
          <b-form-group
            label="Personalité :"
            label-for="nested-country"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-rating v-model="newPlayer.observation.mental.personalite" show-value show-clear stars="4" variant="danger"></b-form-rating>
          </b-form-group>
        </b-form-group>
      </b-card>
      <b-card bg-variant="light" class="mt-3 mb-3">
        <b-form-group
          label-cols-lg="3"
          label="Commentaires"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
        >
          <b-form-group
            label=""
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-textarea v-model="newPlayer.observation.comments" rows="6" max-rows="9"></b-form-textarea>
          </b-form-group>
        </b-form-group>
      </b-card>
      <div class="form-group">
        <b-button :to="{name:'Home'}" class="mr-2" variant="danger">Annuler</b-button>
        <input type="submit" @click="updatePlayer()" class="btn btn-primary" :disabled="!isFormValid" value="Envoyer observation" />
      </div>
    </div>
  </section>
</template>
<script>
import { db } from "@/db";
import navigation from "@/components/NavBar_F.vue";
import firebase from "firebase";
import { VueAutosuggest } from 'vue-autosuggest';

export default {
  data() {
    return {
      user:null,
      optionsPromos: [1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996,1997,1998,1999,2000,2001,2002,2003,2004,2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015,2016,2017,2018,2019,2020],
      optionsStrongfoot: ['G', 'D', 'LES 2'],
      optionsStatus:['A REVOIR','A SUIVRE','SIGNATURE SFC'],
      optionsArea:['Romandie','Suisse alémanique','France voisine', 'EU','Autre'],
      optionsPositions: ['GK','Lat. D', 'Lat. G', 'Def. Axe', 'Mil. DEF', 'Mil. OFF.', 'Att. D', 'Att. G', 'Att. Axe'],
      players:[],
      query: "",
      selected: "",
      newPlayer: {
        childKey: null,
        lastname:null,
        firstname: null,
        //position: null,
        positionList: [],
        promo: null, 
        //status: null,
        club: null,
        area: null,
        //strongfoot: null,
        nationality: null,
        observation: null,
        ageCategory: 'senior'
      },
      observation:{
          createdAt: Date.now(),
          author: "",
          globalNote: 0.5,
          game: "",
          comments:"",
          tactical:{
            vision:null,
            lecture:null,
            jeuSansBallon:null,
            anticipation:null,
            orientationCorps:null,
            duels:null,
          },
          condition:{
            vitesse:null,
            tonicite:null,
            vivacite:null,
            volume:null,
            puissance:null,
          },
          technique:{
            vitesseExecution:null,
            dribbles:null,
            frappe:null,
            jeuDeTete:null,
            passe:null,
            priseDeBalle:null,
          },
          mental:{
            emotions:null,
            determination:null,
            attitude:null,
            personalite:null,
          }
        }
    };
  },
  firebase() {
    return {
      players: db.ref("scouting-players-female"),
    };
  },
  components: {
    navigation, 
    VueAutosuggest,
  },
  created() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.user = user;
      }
    });
    /*if (!this.$store.state.currentUser.isScout) {
      this.$router.push({ name: "Home" });
    }*/
    this.newPlayer.observation = this.observation;
    /*let player = this.player; //this.teamsObj[this.$route.params.id];
    this.newPlayer = {
      firstname: player.firstname,
      lastname: player.lastname,
      position: player.position,
      promo: player.promo, 
      status: player.status,
      club: player.club,
      strongfoot: player.strongfoot,
      nationality: player.nationality
    };*/
    if(this.$route.params.type=='jun'){
      this.newPlayer.ageCategory = 'junior'
    }
    console.log(this.newPlayer);
  },
  computed:{
    isFirstnameValid(){
      return this.newPlayer.firstname && this.newPlayer.firstname.length >= 3 ? true : false; 
    },
    isLastnameValid(){
      return this.newPlayer.lastname && this.newPlayer.lastname.length >= 3 ? true : false; 
    },
    isPositionValid(){
      return this.newPlayer.positionList.length>=1 ? true : false; 
    },
    isPromoValid(){
      return this.newPlayer.promo && this.newPlayer.promo >=1986 && this.newPlayer.promo<=2020 ? true : false; 
    },
    isClubValid(){
      return this.newPlayer.club && this.newPlayer.club.length >= 2 ? true : false; 
    },
    isAreaValid(){
      return this.newPlayer.area!=null ? true : false; 
    },
    isFormValid(){
      return this.isFirstnameValid && this.isLastnameValid && this.isPositionValid && this.isPromoValid && this.isClubValid && this.isAreaValid ? true : false; 
    },
    
    filteredOptions() {
      console.log("filteredOptions");
      if(this.query.length<=1){
        return [{data:[]}];
      }
      return [
        { 
          data: this.players.filter(option => {
            return option.lastname.toLowerCase().indexOf(this.query.toLowerCase())   > -1;
          }).slice(0,8)
        }
      ];
    }
  },
 methods: {
    clickHandler(item) {
      // event fired when clicking on the input
    },
    onSelected(item) {
      console.log(item);
      this.selected = item.item;
      this.newPlayer = this.selected;
      this.newPlayer.childKey = this.newPlayer['.key']
      this.newPlayer.observation = this.observation;
    },
    onInputChange(text) {
      // event fired when the input changes
      if(text.length>=1){
        console.log(text)
        this.newPlayer.lastname = text;
      }
    },
    /**
     * This is what the <input/> value is set to when you are selecting a suggestion.
     */
    getSuggestionValue(suggestion) {
      console.log(suggestion, 'suggestion');
      return suggestion.item.lastname;
    },
    focusMe(e) {
      console.log(e) // FocusEvent
    },

    //When one of the suggestion is clicked
    /*suggestionClick(index) {
        this.newPlayer = this.matches[index];
        this.newPlayer.childKey = this.newPlayer['.key']
        this.newPlayer.observation = this.observation;
        this.open = false;
    },*/
    updatePlayer() {
       /**
       * ADD TEST IF route param teamID is a team of the current user !! 
       */
      
      var observation = this.newPlayer.observation;
      observation.author = this.user.displayName;
      console.log(this.newPlayer);
      this.newPlayer.observation = null;
      var newPlayerKey = null;
      if(this.newPlayer.childKey){
        console.log("updating player");
        newPlayerKey = this.newPlayer.childKey;
        db.ref("scouting-players-female").child(this.newPlayer.childKey).set(this.newPlayer);
      } else{
        console.log("new player");
        newPlayerKey =  db.ref("scouting-players-female").push(this.newPlayer).key;
      }
      observation.playerId = newPlayerKey;
      db.ref("scouting-observations-female").push(observation);
      this.$router.push({name: "ScoutingSearch"});
    }
  }
};
</script>
<style scoped>

ul {
  width: 100%;
  color: rgba(30, 39, 46,1.0);
  list-style: none;
  margin: 0;
  padding: 0.5rem 0 .5rem 0;
}
li {
  margin: 0 0 0 0;
  border-radius: 5px;
  padding: 0.75rem 0 0.75rem 0.75rem;
  display: flex;
  align-items: center;
}
li:hover {
  cursor: pointer;
}

.autosuggest-container {
  display: flex;
  justify-content: center;
  width: 280px;
}

#autosuggest { width: 100%; display: block;}
.autosuggest__results-item--highlighted {
  background-color: rgba(51, 217, 178,0.2);
}
</style>