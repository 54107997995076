<template>
   <main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
    <div class="container">
      <div class="login-card bg-white">
        <div class="row no-gutters">
          <div class="col-md-5">
            <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" class="login-card-img">
              <source src="@/assets/video/video-login.mp4" type="video/mp4">
            </video>
          </div>
          <div class="col-md-7">
            <div class="card-body">
              <div class="brand-wrapper text-center">
                <img src="@/assets/img/gef-logo.png" alt="logo" class="logo">
              </div>
              <p class="login-card-description text-center">Scouting</p>
              <div id="firebaseui-spa">
                  <section id="firebaseui-auth-container"></section>
                </div>
                <!--<nav class="login-card-footer-nav">
                  <a href="#!">Terms of use.</a>
                  <a href="#!">Privacy policy</a>
                </nav>-->
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="card login-card">
        <img src="assets/images/login.jpg" alt="login" class="login-card-img">
        <div class="card-body">
          <h2 class="login-card-title">Login</h2>
          <p class="login-card-description">Sign in to your account to continue.</p>
          <form action="#!">
            <div class="form-group">
              <label for="email" class="sr-only">Email</label>
              <input type="email" name="email" id="email" class="form-control" placeholder="Email">
            </div>
            <div class="form-group">
              <label for="password" class="sr-only">Password</label>
              <input type="password" name="password" id="password" class="form-control" placeholder="Password">
            </div>
            <div class="form-prompt-wrapper">
              <div class="custom-control custom-checkbox login-card-check-box">
                <input type="checkbox" class="custom-control-input" id="customCheck1">
                <label class="custom-control-label" for="customCheck1">Remember me</label>
              </div>              
              <a href="#!" class="text-reset">Forgot password?</a>
            </div>
            <input name="login" id="login" class="btn btn-block login-btn mb-4" type="button" value="Login">
          </form>
          <p class="login-card-footer-text">Don't have an account? <a href="#!" class="text-reset">Register here</a></p>
        </div>
      </div> -->
    </div>
  </main>
</template>
<script>
import firebase from "firebase";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

export default {
  name: "Login",
  data() {
    return {};
  },
  components: {},
  mounted() {
    var signInUrl = "/";
    if (this.$route.params.teamLink) {
      signInUrl = "/jointeam/" + this.$route.params.teamLink;
    }
    if (this.$route.params.teamId && this.$route.params.playlistId) {
      signInUrl =
        "/vpguest/" +
        this.$route.params.teamId +
        "/" +
        this.$route.params.playlistId;
    }
    let ui = firebaseui.auth.AuthUI.getInstance();
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(firebase.auth());
    }
    var uiConfig = {
      signInSuccessUrl: signInUrl, // This redirect can be achived by route using callback.
      signInFlow: "popup",
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      signInOptions: [
        {
          // The Provider you need for your app. We need the Phone Auth
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: true,
          recaptchaParameters: {
            type: "image", // another option is 'audio'
            size: "invisible", // other options are 'normal' or 'compact'
            badge: "bottomleft", // 'bottomright' or 'inline' applies to invisible.
          },
        },
      ],
    };
    ui.start("#firebaseui-auth-container", uiConfig);
  },
};
</script>
<style scoped>
@import "../assets/css/login.css";
main{
  background-image: linear-gradient( 96.5deg,  rgba(39,103,187,1) 10.4%, rgba(16,72,144,1) 87.7% );

}
</style>