<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    name: "App"
};
</script>

<style >
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

body{
    font-family: 'Nunito Sans', sans-serif !important;
    background-color: #efefef !important;
}
#app{
    background-color: #efefef;
}
.mainView{
  margin-top: -70px;
  margin-bottom: 55px;
  padding-top:20px;
  padding-bottom:20px;
  background-color: white;
  border-radius: 5px;
  min-height: 66vh;
}
@media only screen and (max-width: 1026px) {
    .mainView {
        border-radius: 0px;
        margin-bottom: 0px;
    }
}
</style>
