<template>
  <section>
   
  </section>
</template>

<script>
import firebase from "firebase";

import { db } from "@/db";

export default {
  data() {
    return {
      user: null,
      teams: [],
      isLoading: false,
      userRole:null,
    };
  },
  created() {
    //this.isLoading = true;
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        
        this.user = user;
        var dbUser = {
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          lastActivity: Date.now(),
        };
        var userRole = 'PLAYER';
        db.ref("users").once("value", snapshot => {
          if (!snapshot.hasChild(user.uid)) {
            dbUser.role = userRole; //set default role
            db.ref("users/" + user.uid)
              .set(dbUser)
              .then(() => {
                console.log("user created!");
              });
          }else{
            dbUser.role = snapshot.child(user.uid).val().role;
            if(snapshot.child(user.uid).val().isScout){
              dbUser.isScout = snapshot.child(user.uid).val().isScout;
            }
            db.ref("users/" + user.uid)
              .set(dbUser)
              .then(() => {
                console.log("user updated!");
              });
          }
          this.addUserProperties(dbUser)
        });
               
      }
    });
  },
  computed: {
    
  },

  mounted() {
  
  },
  components: {
    
  }, 
  methods:{
    addUserProperties(dbUser){
      this.userRole = dbUser.role;
      this.$analytics.logEvent("login");
      this.$analytics.setUserId(dbUser.uid);
      this.$analytics.setUserProperties({
        account_type: dbUser.role // can help you to define audiences
      });
      
      this.$store.dispatch("setCurrentUser", dbUser); // set db user to store

      db.ref("scouting-role").child(dbUser.uid).once("value", snapshot => {
          var userScoutingRoleInfo = snapshot.val();
          this.$store.dispatch("setCurrentScoutRole", userScoutingRoleInfo);
          this.$store.dispatch("setCurrentScoutArea", userScoutingRoleInfo);
          if(['TOPMANAGER_F','JUN_MANAGER_F', 'SCOUT_F'].includes(userScoutingRoleInfo.role)){
            this.$router.push({ name: 'Home_F'});
          } else {
            this.$router.push({ name: 'Home_M'});
          }
        });          
    }
  }
};
</script>

<style scoped>
</style>
