<template>
  <section style="background-color: rgb(55, 71, 79	); min-height:100vh;">
    <navigation></navigation>
    <jumbo></jumbo>
    <div class="container">
      <section>
        <div class="card-deck mb-4" >
          <!--v-if="$store.state.currentUser.role=='ADMIN' || $store.state.currentUser.role=='COACH'"-->
          <router-link
            tag="div"
            class="card shadow border-0 rounded bg-secondary"
            :to="{ name: 'ScoutingObservationAdd'}"
          >
            <b-icon
              icon="person-plus"
              font-scale="5"
              variant="light"
              class="card-img mt-4"
            ></b-icon>
            <div class="h5 text-center text-white">
              Nouveau rapport Joueur
            </div>
          </router-link>
          
          <router-link
            tag="div"
            class="card shadow border-0 rounded bg-secondary"
            :to="{ name: 'ScoutingObservationAddGK'}"
          >
            <b-icon
              icon="person-plus"
              font-scale="5"
              variant="light"
              class="card-img mt-4"
            ></b-icon>
            <div class="h5 text-center text-white">
              Nouveau rapport Gardien
            </div>
          </router-link>

          <router-link
            tag="div"
            class="card shadow border-0 rounded bg-secondary"
            :to="{ name: 'ScoutingObservationList'}"
            v-if="$store.state.currentScoutRole=='ADMIN' || $store.state.currentScoutRole=='TOPMANAGER' || $store.state.currentScoutRole=='CLUBMANAGER'"
          >   
            <b-icon
              icon="card-list"
              font-scale="5"
              variant="light"
              class="card-img mt-4"
            ></b-icon>
            <div class="h5 text-center text-white">
              Liste Observations
            </div>
          </router-link>

          <router-link
            tag="div"
            class="card shadow border-0 rounded bg-secondary"
            :to="{ name: 'ScoutingSearch'}"
            v-if="$store.state.currentScoutRole=='ADMIN' || $store.state.currentScoutRole=='TOPMANAGER' || $store.state.currentScoutRole=='CLUBMANAGER'"
          >   
            <b-icon
              icon="people-fill"
              font-scale="5"
              variant="light"
              class="card-img mt-4"
            ></b-icon>
            <div class="h5 text-center text-white">
              Liste Joueurs
            </div>
          </router-link>
          
          <!--<router-link
            tag="div"
            class="card shadow border-0 rounded bg-secondary"
            style="opacity: 50%"
            :to="{ name: 'Home'}"
            v-if="$store.state.currentScoutRole=='ADMIN' || $store.state.currentScoutRole=='TOPMANAGER' || $store.state.currentScoutRole=='CLUBMANAGER'"
          >
            <b-icon
              icon="files"
              font-scale="5"
              variant="light"
              class="card-img mt-4"
            ></b-icon>
            <div class="h5 text-center text-white">
              Feuilles de matchs
            </div>
          </router-link>-->
        </div>
        <footerCool></footerCool>
      </section>
    </div>
  </section>
</template>

<script>
import navigation from "@/components/NavBar.vue";
import jumbo from "@/components/Jumbotron.vue";
import footerCool from "@/components/FooterCool.vue";
import firebase from "firebase";
import { db } from "@/db";

export default {
  data() {
    return {
      user: null,
      teams: [],
      isLoading: false,
    };
  },
  created() {

  },
  computed: {
    
  },

  mounted() {
  
  },
  components: {
    navigation,
    jumbo,
    footerCool
  }
};
</script>

<style scoped>
.card:hover{
  cursor: pointer;
}
a:hover{
  text-decoration:none;
}
</style>
