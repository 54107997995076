import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase'
import { parse } from 'semver'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentUser: null,
    currentScoutRole: null,
    currentScoutArea: null,
  },
  mutations: {
    SET_CURRENT_USER(state, user) {
      state.currentUser = user;
    },
    SET_CURRENT_ROLE_SCOUT(state, currentScoutRole) {
      state.currentScoutRole = currentScoutRole;
    },
    SET_CURRENT_ROLE_AREA(state, currentScoutArea) {
      state.currentScoutArea = currentScoutArea;
    },
  },
  actions: {
    setCurrentUser(context, user){
      context.commit('SET_CURRENT_USER', user);
    },
    setCurrentScoutRole(context, role){
      context.commit('SET_CURRENT_ROLE_SCOUT', role.role);
    },
    setCurrentScoutArea(context, role){
      context.commit('SET_CURRENT_ROLE_AREA', role.area);
    },
  },
  modules: {
  }
})
