import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/components/Login'
import Home from '@/components/Home'
import Home_F from '@/components/Home_F'
import Home_M from '@/components/Home_M'
import ScoutingSearch from '@/components/ScoutingSearch'
import ScoutingSearch_F from '@/components/ScoutingSearch_F'
import ScoutingPlayerEdit from '@/components/ScoutingPlayerEdit'
import ScoutingPlayerEdit_F from '@/components/ScoutingPlayerEdit_F'
import ScoutingObservationAdd from '@/components/ScoutingObservationAdd'
import ScoutingObservationAdd_F from '@/components/ScoutingObservationAdd_F'
import ScoutingObservationAddGK from '@/components/ScoutingObservationAddGK'
import ScoutingObservationList from '@/components/ScoutingObservationList'
import ScoutingObservationList_F from '@/components/ScoutingObservationList_F'
import ScoutingPlayerProfile from '@/components/ScoutingPlayerProfile'
import ScoutingPlayerProfile_F from '@/components/ScoutingPlayerProfile_F'
import UsersList from '@/components/UsersList'
import UserEdit from '@/components/UserEdit'

import firebase from 'firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/scouting/search',
    name: 'ScoutingSearch',
    component: ScoutingSearch,
    meta: {
      auth: true
    }

  },
  {
    path: '/scouting/list',
    name: 'ScoutingSearch_F',
    component: ScoutingSearch_F,
    meta: {
      auth: true
    }

  },
  {
    path: '/scouting/:scoutingPlayerId/profile',
    name: 'ScoutingPlayerProfile',
    component: ScoutingPlayerProfile,
    meta: {
      auth: true
    }

  },
  {
    path: '/scouting/:scoutingPlayerId/details',
    name: 'ScoutingPlayerProfile_F',
    component: ScoutingPlayerProfile_F,
    meta: {
      auth: true
    }

  },
  {
    path: '/scouting/:scoutingPlayerId/edit',
    name: 'ScoutingPlayerEdit',
    component: ScoutingPlayerEdit,
    meta: {
      auth: true
    }

  },
  {
    path: '/scouting/:scoutingPlayerId/edition',
    name: 'ScoutingPlayerEdit_F',
    component: ScoutingPlayerEdit_F,
    meta: {
      auth: true
    }

  },
  {
    path: '/scouting/observation/new',
    name: 'ScoutingObservationAdd',
    component: ScoutingObservationAdd,
    meta: {
      auth: true
    }

  },
  {
    path: '/scouting/observation/add/:type?',
    name: 'ScoutingObservationAdd_F',
    component: ScoutingObservationAdd_F,
    meta: {
      auth: true
    }

  },
  {
    path: '/scouting/observation/new/gk',
    name: 'ScoutingObservationAddGK',
    component: ScoutingObservationAddGK,
    meta: {
      auth: true
    }

  },
  {
    path: '/scouting/observations',
    name: 'ScoutingObservationList',
    component: ScoutingObservationList,
    meta: {
      auth: true
    }

  },
  {
    path: '/scouting/observations/list',
    name: 'ScoutingObservationList_F',
    component: ScoutingObservationList_F,
    meta: {
      auth: true
    }

  },
  {
    path: '/users',
    name: 'UsersList',
    component: UsersList,
    meta: {
      auth: true
    }

  },
  {
    path: '/user/:id/edit',
    name: 'UserEdit',
    component: UserEdit,
    meta: {
      auth: true
    }

  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      guest: true
    }

  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      auth: true
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      auth: true
    }
  },
  {
    path: '/accueil',
    name: 'Home_F',
    component: Home_F,
    meta: {
      auth: true
    }
  },
  {
    path: '/home/m',
    name: 'Home_M',
    component: Home_M,
    meta: {
      auth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.auth)) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        next()
      } else {
        next({
          path: "/login",
        })
      }
    })
  } else if (to.matched.some(record => record.meta.guest)) {
    /*firebase.auth().onAuthStateChanged(user => {
      if (user) {
        next({
          path: "/login",
        })
      } else {
        next()
      }

    })*/
    next()

  } else {
    next()
  }

})

export default router
