<template>
  <footer class="container p-4 mt-5 border-top text-white">
    <p>GEF Scouting © Version 1.0 [bêta] made with &#129321; during #stayAtHome period</p>
  </footer>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {}
};
</script>
<style scoped>
</style>