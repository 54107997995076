<template>
  <section>
    <navigation></navigation>
    <div class="container mt-3">
      <b-form-group label="Rechercher : " label-size="lg" label-cols-sm="2" label-for="filterInput">
        <b-input-group size="lg">
          <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Joueur, club, année,..." size="lg"></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
              <b-icon icon="backspace-fill"></b-icon>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group label="Statut :" label-cols-sm="2" label-for="statusInput">
        <b-button-group size="sm" class="mt-1" >
          <b-button v-for="status in optionsStatusFilter" id="statusInput" v-bind:key="status.key" variant="secondary" @click="filter = status">{{status}}</b-button>
        </b-button-group>
      </b-form-group>
      <!--<b-button @click="csvExport(getScoutingPlayers)" size="sm" class="my-1">Exporter (.csv)&nbsp;<b-icon icon="box-arrow-up-right" /> </b-button>-->

      <div class="my-2">{{ scoutingPlayers.length }} joueurs</div>
      <b-alert :show="true" v-if="$store.state.currentScoutRole=='ADMIN' || $store.state.currentScoutRole=='TOPMANAGER'">
        <b-icon icon="info-circle-fill" />
         &nbsp;* Le nombre de critères pour calculer les moyennes peuvent varier selon les observations des joueurs. Min = 0 Max = 5
      </b-alert>
      <b-table
        show-empty
        small
        :items="getScoutingPlayers"
        :fields="fields"
        :filter="filter"
        :filterIncludedFields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
        striped
        hover
      >
        <template v-slot:cell(actions)="row">
          <router-link :to="{ name: 'ScoutingPlayerProfile', params: {scoutingPlayerId: row.item['.key']} }">
            <b-icon icon="eye" class="rounded bg-light p-1" variant="dark" font-scale="1.5"></b-icon>
          </router-link>
          <router-link :to="{ name: 'ScoutingPlayerEdit', params: {scoutingPlayerId: row.item['.key']} }">
            <b-icon icon="pencil" class="rounded bg-light p-1" variant="dark" font-scale="1.5"></b-icon>
          </router-link>
          <b-icon icon="trash" class="rounded bg-danger p-1" variant="light" font-scale="1.5" v-if="$store.state.currentScoutRole=='ADMIN'" @click="deletePlayer(row.item)"></b-icon>
        </template>

        <template v-slot:cell(lastname)="row">{{row.item.lastname}}</template>
        <template v-slot:cell(firstname)="row">{{row.item.firstname}}</template>
        <template v-slot:cell(position)="row">
          <span v-if="row.item.positionList">{{row.item.positionList.join(', ')}}</span>
          <span v-if="row.item.position && !row.item.positionList">{{row.item.position}}</span>
        </template>
        <template v-slot:cell(meanTE)="row">{{ getObservationsMean(row.item, 'technique')}}</template>
        <template v-slot:cell(meanTA)="row">{{ getObservationsMean(row.item, 'tactical')}}</template>
        <template v-slot:cell(meanCO)="row">{{ getObservationsMean(row.item, 'condition')}}</template>
        <template v-slot:cell(meanME)="row">{{ getObservationsMean(row.item, 'mental')}}</template>
        </b-table>
    </div>
  </section>
</template>

<script>
import { db } from "@/db";
import navigation from "@/components/NavBar.vue";

export default {
  data() {
    return {
      scoutingPlayers: [],
      observations:[],
      fields: [
        { key: "actions", label: "Actions" },
        { key: "lastname", label: "Nom", sortable: true },
        { key: "firstname", label: "Prénom", sortable: true },
        { key: "position", label: "Position", sortable: true },
        { key: "promo", label: "Promo", sortable: true },
        { key: "status", label: "Statut ", sortable: true },
        { key: "club", label: "Club", sortable: true },
        { key: "area", label: "Zone", sortable: true },
        { key: "strongfoot", label: "Pied fort", sortable: true },
        { key: "nationality", label: "Nationalité", sortable: true },
      ],
      totalRows: 1,
      sortBy: "name",
      sortDesc: true,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      optionsStatusFilter:['A REVOIR','A SUIVRE','SIGNATURE SFC'],
    };
  },
  components: {
    navigation
  },
  firebase() {
    return {
      scoutingPlayers: db.ref("scouting-players"),
      observations: db.ref("scouting-observations"),
    };
  },
  created() {
    var rolesAccepted = ['ADMIN', 'TOPMANAGER', 'CLUBMANAGER'];
    if(!this.$store.state.currentUser || !this.$store.state.currentScoutRole || !rolesAccepted.includes(this.$store.state.currentScoutRole)){
      this.$router.push({ name: 'Home'});
    }
    if(this.$store.state.currentScoutRole=='ADMIN' || this.$store.state.currentScoutRole=='TOPMANAGER'){
      this.fields.push({ key: "meanTE", label: "Moy TE* ", sortable: true });
      this.fields.push({ key: "meanTA", label: "Moy TA* ", sortable: true });
      this.fields.push({ key: "meanCO", label: "Moy CO* ", sortable: true });
      this.fields.push({ key: "meanME", label: "Moy ME* ", sortable: true });
    }
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      //this.currentPage = 1
    },
    csvExport: function (arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map((item) => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
    deletePlayer(player){
      console.log("player");
      console.log({ player });
      console.log(player[".key"]);
      if (this.$store.state.currentScoutRole=='ADMIN' && confirm("Veuillez confirmer la suppression du joueur : "+player.lastname)) {
          db
          .ref("scouting-observations")
          .orderByChild("playerId")
          .equalTo(player[".key"])
          .once("value", snapshot => {
            snapshot.forEach(element => {
              var key = element.key;
              db
              .ref("scouting-observations")
              .child(key).remove();
            });
          });
        return db
          .ref("scouting-players")
          .child(player[".key"])
          .remove();
      }
      return;
    },
    getObservationsMean(player, type){
      //console.log(player,'player')
      //console.log(this.observations,'this obs')
      var playersObservations = this.observations.filter(pl => pl.playerId==player['.key']);
      if(playersObservations.length==0){
        return 0
      }
      var sum = 0;
      var i = 0;
      playersObservations.forEach(obs => {
        console.log(type);
        console.log(obs);
        console.log(obs[type], 'obs[type]');
        if(!obs[type] || Object.entries(obs[type]).length==0){
          return 0;
        }
        for (const [crit, value] of Object.entries(obs[type])) {
          console.log(value, 'crit');
          sum += value;
          i++;
        }
      });
      return i > 0 ? (sum/i).toFixed(1) : 0
    }
  },
  computed: {
    getScoutingPlayers(){
      if(this.$store.state.currentScoutRole=='CLUBMANAGER' && this.$store.state.currentScoutArea!=null){
        return this.scoutingPlayers.filter(player => (player.area==this.$store.state.currentScoutArea || player.area=='AUTRE'));
      }
      var rolesAccepted = ['ADMIN', 'TOPMANAGER', 'CLUBMANAGER'];
      if(rolesAccepted.includes(this.$store.state.currentScoutRole) || (this.$store.state.currentScoutRole=='CLUBMANAGER' && this.$store.state.currentScoutArea=='ALL')){
        return this.scoutingPlayers;
      }
      return [];
    }
  }
};
</script>
<style scoped>
</style>

