<template>
  <section>
    <navigation></navigation>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h3>Éditer une membre : {{user.displayName}}</h3>
        </div>
        <div class="card-body">
          <form v-on:submit.prevent="updateUser">
            <div class="form-group">
              <label>Role :</label>
              <b-form-select v-model="scoutingRole" :options="optionsRole"></b-form-select>
            </div>
            <div class="form-group">
              <label>Zone :</label>
              <b-form-select v-model="scoutingArea" :options="optionsArea"></b-form-select>
            </div>
            <div class="form-group">
              <b-button :to="{name:'UsersList'}" class="mr-2" variant="danger">Annuler</b-button>
              <input type="submit" class="btn btn-primary" value="Éditer membre" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { db } from "@/db";
import navigation from "@/components/NavBar.vue";

export default {
  data() {
    return {
      user: {},
      scoutingRole:null,
      scoutingArea:null,
      optionsRole: ['ADMIN','TOPMANAGER_F','JUN_MANAGER_F', 'SCOUT_F','TOPMANAGER','CLUBMANAGER', 'SCOUT'],
      optionsArea: [null,'ACGF','CAROUGE','MEYRIN', 'SERVETTE', 'ALL'],
    };
  },
  firebase() {
    return {
      user: db.ref("users").child(this.$route.params.id),
      scoutingRoleInfos: db.ref("scouting-role").child(this.$route.params.id),
    };
  },
  components: {
    navigation
  },
  created() {
    if(!this.$store.state.currentUser || this.$store.state.currentUser.role!='ADMIN'){
      this.$router.push({ name: 'Home'});
    }
    if(this.scoutingRoleInfos){
      this.scoutingRole = this.scoutingRoleInfos.role;
      this.scoutingArea = this.scoutingRoleInfos.area;
    }
  },
  methods: {
    updateUser() {
      console.log(this.scoutingRole);
      console.log(this.user);
      var roleInfos = {
        role: this.scoutingRole,
        area: this.scoutingArea,
      };
      db.ref("scouting-role")
        .child(this.$route.params.id)
        .set(roleInfos);
      this.$router.push("/users");
      this.scoutingRole = null;
      this.scoutingArea = null;
    }
  }
};
</script>