<template>
  <section>
    <navigation></navigation>
    <div class="container">
      <div class="row">
      <div class="order-xl-1 mt-5 pt-5 mb-5 col-lg-4">
        <div alt="Image placeholder" class="card card-profile mb-3">
          <!----><!---->
          <div class="row justify-content-center">
            <div class="order-lg-2 col-lg-3">
              <div class="card-profile-image">
                <a href="#"
                  ><img
                    src="@/assets/img/member-profile-dft.png"
                    class="rounded-circle"
                /></a>
              </div>
            </div>
          </div>
          <div
            class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"
          >
            <div class="d-flex justify-content-between">
              <b-badge v-if="player.status" class="p-2">{{ player.status }}</b-badge>
              <b-badge v-if="player.strongfoot" class="p-2">{{
                player.strongfoot == 'D' ?  'Droitier' : player.strongfoot == 'G' ? 'Gaucher' : 'Ambipode'
              }}</b-badge>
            </div>
          </div>
          <div class="card-body pt-0">
            <!----><!---->
            <div class="row">
              <div class="col">
                <div
                  class="
                    card-profile-stats
                    d-flex
                    justify-content-center
                    mt-md-5
                    text-center
                  "
                >
                  <div class="mb-4">
                    <div class="h2 pb-0">{{ observations.length }}</div>
                    <span class="description">Observations</span>
                  </div>
                  <!--<div>
                    <span class="heading">10</span
                    ><span class="description">Photos</span>
                  </div>
                  <div>
                    <span class="heading">89</span
                    ><span class="description">Comments</span>
                  </div>-->
                </div>
              </div>
            </div>
            <div class="text-left">
              <div class="h3 mb-4">
                {{ player.lastname }} {{ player.firstname }}<br />
                <span class="font-weight-light"> {{ player.promo }}</span>
              </div>
              <hr />
              <div class="">
                <b-icon icon="geo-fill" />&nbsp;Nationalité :&nbsp;<b>{{ player.nationality }}</b>
              </div>
              <hr />
              <div class="mt-4">
                <b-icon icon="trophy"/>&nbsp;Club :&nbsp;<b>{{ player.club }}</b>
              </div>
              <hr />
              <div class="mt-4">
                <b-icon icon="geo-alt-fill"/>&nbsp;Position : &nbsp;<b>{{ player.positionList ? player.positionList.join(', ') : player.position }}</b>
              </div>
              <!--<hr class="my-4" />
              <p>
                {{player.comments}}
              </p>-->
              <hr class="my-4" v-if="player.suivi" />
              <div class="lead" v-if="player.suivi">Suivi</div>
              <p v-if="player.suivi">
                {{player.suivi}}
              </p>
            </div>
          </div>
          <!----><!---->
        </div>
        <div alt="Image placeholder" class="card card-profile">
          <div class="card-header text-left border-0 pt-8 pt-md-4 pb-0 pb-md-4">
            <div class="lead">
              Documents
            </div>
          </div>
          <div class="card-body">
            <div class="text-center mb-4" v-if="filesList.length==0">Aucun document répertorié</div>
            <div v-if="filesList.length>0" class="d-flex flex-wrap">
              <div v-for="file in filesList" v-bind:key="file.key" style="min-width:50%">
                <div class="rounded p-2 border m-1">
                  <a :href="'https://treat.gefvideo.ch'+file.substring(1)" target="_blank" download class="d-flex flex-column align-items-center small text-wrap">
                    <span><b-icon icon="file-image" font-scale="3"/></span>
                    <span>{{file.replace(/^.*[\\\/]/, '')}}</span>
                  </a>
                  <hr class="my-1"/>
                  <b-button variant="danger" size="sm" @click="deletePlayerFile(file)" class="float-right"><b-icon icon="trash" /></b-button>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <fileUpload
              @transferEnded="transferEnded"
              v-bind:folder="folder"
              url="scoutingfiles"
            ></fileUpload>
          </div>
        </div>
      </div>
      <div class="order-xl-2 col-lg-8 mt-5 pt-5">
        <div class="card mb-2" v-for="(observation, index) in getObservations" v-bind:key="index">
          <!----><!---->
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col-4"><h3 class="mb-0">Observation {{getObservations.length-index}}</h3> <small>{{observation.author}}</small></div>
              <div class="text-right col-8 d-flex align-items-center justify-content-end">
                <div>{{ moment.utc(observation.createdAt).format("dddd DD MMMM YYYY") }}</div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4">
                <span>Appréciation globale :</span>
              </div>
              <div class="col-lg-8 pt-1">
              
                <b-progress :max="3">
                  <b-progress-bar :value="observation.globalNote" >
                    {{observation.globalNote}} / 3
                  </b-progress-bar>
                </b-progress>
              </div>
            </div>
            <hr/>
            <div class="row" v-if="observation.gardien">  
              <div class="col-lg-3">
                <span class="heading">Gardien</span>
                <div v-if="observation.gardien.courage">
                    Courage : 
                    <b-form-rating v-model="observation.gardien.courage" readonly variant="success"></b-form-rating>
                </div>
                <div v-if="observation.gardien.communication">
                    Communication : 
                    <b-form-rating v-model="observation.gardien.communication" readonly variant="success"></b-form-rating>
                </div>
                <div v-if="observation.gardien.decisif">
                    Décisif : 
                    <b-form-rating v-model="observation.gardien.decisif" readonly variant="success"></b-form-rating>
                </div>
                <div v-if="observation.gardien.techniqueOff">
                    Technique offensive : 
                    <b-form-rating v-model="observation.gardien.techniqueOff" readonly variant="success"></b-form-rating>
                </div>
                <div v-if="observation.gardien.techniqueDef">
                    Technique défensive : 
                    <b-form-rating v-model="observation.gardien.techniqueDef" readonly variant="success"></b-form-rating>
                </div>
                <div v-if="observation.gardien.tactiqueOff">
                    Tactique offensive : 
                    <b-form-rating v-model="observation.gardien.tactiqueOff" readonly variant="success"></b-form-rating>
                </div>
                <div v-if="observation.gardien.tactiqueDef">
                    Tactique défensive : 
                    <b-form-rating v-model="observation.gardien.tactiqueDef" readonly variant="success"></b-form-rating>
                </div>
              </div>
            </div>
            <div class="row" v-if="!observation.gardien">
              <div class="col-lg-3">
                <span class="heading">Tactique</span>
                <div v-if="observation.tactical && observation.tactical.vision">
                  Vision du jeu : 
                  <b-form-rating v-model="observation.tactical.vision" readonly variant="success"></b-form-rating>
                </div>
                <div v-if="observation.tactical && observation.tactical.lecture">
                  Lecture du jeu : 
                  <b-form-rating v-model="observation.tactical.lecture" readonly variant="success"></b-form-rating>
                </div>
                <div v-if="observation.tactical && observation.tactical.jeuSansBallon">
                  Jeu sans ballon : 
                  <b-form-rating v-model="observation.tactical.jeuSansBallon" readonly variant="success"></b-form-rating>
                </div>
                <div v-if="observation.tactical && observation.tactical.casseLesLignes">
                  Casse les lignes : 
                  <b-form-rating v-model="observation.tactical.casseLesLignes" readonly variant="success"></b-form-rating>
                </div>
                <div v-if="observation.tactical && observation.tactical.orientationCorps">
                  Orient. du corps : 
                  <b-form-rating v-model="observation.tactical.orientationCorps" readonly variant="success"></b-form-rating>
                </div>
                <div v-if="observation.tactical && observation.tactical.duels">
                  Duels : 
                  <b-form-rating v-model="observation.tactical.duels" readonly variant="success"></b-form-rating>
                </div>
              </div>
              <div class="col-lg-3">
                <span class="heading">Condition</span>
                <div v-if="observation.condition && observation.condition.vitesse">
                  Vitesse : 
                  <b-form-rating v-model="observation.condition.vitesse" readonly variant="warning"></b-form-rating>
                </div>
                <div v-if="observation.condition && observation.condition.tonicite">
                  Tonicité : 
                  <b-form-rating v-model="observation.condition.tonicite" readonly variant="warning"></b-form-rating>
                </div>
                <div v-if="observation.condition && observation.condition.vivacite">
                  Vivacité : 
                  <b-form-rating v-model="observation.condition.vivacite" readonly variant="warning"></b-form-rating>
                </div>
                <div v-if="observation.condition && observation.condition.volume">
                  Volume de jeu : 
                  <b-form-rating v-model="observation.condition.volume" readonly variant="warning"></b-form-rating>
                </div>
                <div v-if="observation.condition && observation.condition.puissance">
                  Puissance : 
                  <b-form-rating v-model="observation.condition.puissance" readonly variant="warning"></b-form-rating>
                </div>
              </div>
              <div class="col-lg-3">
                <span class="heading">Technique</span>
                <div v-if="observation.technique && observation.technique.vitesseExecution">
                  Vit. éxécution : 
                  <b-form-rating v-model="observation.technique.vitesseExecution" readonly variant="primary"></b-form-rating>
                </div>
                <div v-if="observation.technique && observation.technique.percussion">
                  Percussion : 
                  <b-form-rating v-model="observation.technique.percussion" readonly variant="primary"></b-form-rating>
                </div>
                <div v-if="observation.technique && observation.technique.frappe">
                  Frappe : 
                  <b-form-rating v-model="observation.technique.frappe" readonly variant="primary"></b-form-rating>
                </div>
                <div v-if="observation.technique && observation.technique.jeuDeTete">
                  Jeu de tête : 
                  <b-form-rating v-model="observation.technique.jeuDeTete" readonly variant="primary"></b-form-rating>
                </div>
                <div v-if="observation.technique && observation.technique.passe">
                  Passe : 
                  <b-form-rating v-model="observation.technique.passe" readonly variant="primary"></b-form-rating>
                </div>
                <div v-if="observation.technique && observation.technique.priseDeBalle">
                  Prise de balle : 
                  <b-form-rating v-model="observation.technique.priseDeBalle" readonly variant="primary"></b-form-rating>
                </div>
              </div>
              <div class="col-lg-3">
                <span class="heading">Mental</span>
                <div v-if="observation.mental && observation.mental.emotions">
                  Emotions : 
                  <b-form-rating v-model="observation.mental.emotions" readonly variant="danger"></b-form-rating>
                </div>
                <div v-if="observation.mental && observation.mental.determination">
                  Détermination : 
                  <b-form-rating v-model="observation.mental.determination" readonly variant="danger"></b-form-rating>
                </div>
                <div v-if="observation.mental && observation.mental.attitude">
                  Attitude : 
                  <b-form-rating v-model="observation.mental.attitude" readonly variant="danger"></b-form-rating>
                </div>
                <div v-if="observation.mental && observation.mental.polyvalence">
                  Polyvalence : 
                  <b-form-rating v-model="observation.mental.polyvalence" readonly variant="danger"></b-form-rating>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-lg-12">
                {{observation.comments}}
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="text-right d-flex align-items-center justify-content-end">
              <b-button variant="primary" size="sm" class="ml-2">Editer</b-button>
              <b-button variant="danger" size="sm" class="ml-2">Supprimer</b-button>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </section>
</template>

<script>
import navigation from "@/components/NavBar.vue";
import firebase from "firebase";
import { db } from "@/db";
import fileUpload from "@/components/FileUploadMin.vue";
import axios from "axios"; 

export default {
  data() {
    return {
      player: {},
      observations: [],
      folder: 'player_'+this.$route.params.scoutingPlayerId,
      filesList : [],
    };
  },
  firebase() {
    return {
      player: db
        .ref("scouting-players")
        .child(this.$route.params.scoutingPlayerId),
      /*observations: db
        .ref("scouting-observations")
        .child(this.$route.params.scoutingPlayerId),*/
        observations: db
        .ref("scouting-observations")
        .orderByChild("playerId")
        .equalTo(this.$route.params.scoutingPlayerId)
    };
  },
  created() {},
  mounted() {
    this.getPlayerFolderFiles();
  },
  computed:{
    getObservations(){
      var observations = [];
      observations = this.observations.reverse();
      return observations;
    }
  },
  methods:{
    transferEnded(file){
      console.log("transferEnded");
      this.getPlayerFolderFiles();
    },
    getPlayerFolderFiles(){
      //this.filesList = ["./scoutingfiles/player_-N3ND_K2kjxsHLzoOAzG/sfv_logo_bold_rgb.png","./scoutingfiles/player_-N3ND_K2kjxsHLzoOAzG/sfv_rgb.png","./scoutingfiles/player_-N3ND_K2kjxsHLzoOAzG/sfv_rgb.png"];
      //return;
      var data = JSON.stringify({playerId: this.$route.params.scoutingPlayerId});
        axios.post('https://treat.gefvideo.ch/get-player-scouting-files', data, {headers: {"Content-Type": "application/json"}})
        .then(res => {
          console.log("imported files list");
          this.filesList = res.data;
        })
        .catch(err => console.log(err));
    }, 
    deletePlayerFile(filename){
      if(confirm("Veuillez confirmer la suppression du fichier : "+filename.replace(/^.*[\\\/]/, ''))) {
        var data = JSON.stringify({filename: filename});
          axios.post('https://treat.gefvideo.ch/delete-file', data, {headers: {"Content-Type": "application/json"}})
          .then(res => {
            console.log("deleted file");
            this.getPlayerFolderFiles();
          })
          .catch(err => console.log(err));
      }
    }
  },
  components: {
    navigation,
    fileUpload
  },
};
</script>

<style scoped>
.card-profile-image {
  position: relative;
}
.card-profile-image img {
  max-width: 120px;
  border-radius: 0.375rem;
  -webkit-transform: translate(-50%, -30%);
  transform: translate(-50%, -30%);
  position: absolute;
  left: 50%;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}
.card-profile-image img,
.shadow {
  -webkit-box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.card-profile-stats {
  padding: 1rem 0;
}
.card-profile-stats > div .heading {
  font-size: 1.1rem;
  font-weight: 700;
  display: block;
}
.heading,
.heading-small {
  text-transform: uppercase;
}
.heading {
  letter-spacing: 0.025em;
  font-size: 0.95rem;
  font-weight: 600;
}
.card-profile-stats > div .description {
  font-size: 0.875rem;
  color: #adb5bd;
}
</style>
