<template>
  <section>
    <navigation></navigation>
    <div class="container">
      <div class="card mt-3">
        <div class="card-header">
          <h3>Éditer une joueuse</h3>
        </div>
        <div class="card-body">
          <form v-on:submit.prevent="updatePlayer">
            <div class="form-group">
              <label>Prénom :</label>
              <b-form-input type="text" class="form-control" v-model="newPlayer.firstname" :state="isFirstnameValid"/>
              <b-form-invalid-feedback :state="isFirstnameValid">
                Le prénom de la joueuse doit être composé d'au moins 3 lettres
              </b-form-invalid-feedback>
            </div>
            <div class="form-group">
              <label>Nom :</label>
              <b-form-input type="text" class="form-control" v-model="newPlayer.lastname"  :state="isLastnameValid"/>
              <b-form-invalid-feedback :state="isLastnameValid">
                Le nom de la joueuse doit être composé d'au moins 3 lettres
              </b-form-invalid-feedback>
            </div>
            <b-form-group
              label="Position : "
              v-slot="{ ariaDescribedby }"
            >
              <b-form-checkbox-group
                  id="btn-radios-3"
                  v-model="newPlayer.positionList"
                  :options="optionsPositions"
                  button-variant="outline-secondary"
                  name="radio-btn-outline"
                  buttons
              ></b-form-checkbox-group>
              <div v-if="newPlayer.position" class="text-muted"><i>Ancienne valeur : {{newPlayer.position}}</i></div>
              <b-form-invalid-feedback :state="isPositionValid">
                Au moins une sélection doit être entrée pour la position de la joueuse
              </b-form-invalid-feedback>
            </b-form-group>
            
            <div class="form-group">
              <label>Promo :</label>
              <b-form-select v-model="newPlayer.promo" :options="optionsPromos" :state="isPromoValid"></b-form-select>
              <b-form-invalid-feedback :state="isPromoValid">
                La promo de la joueuse doit être indiquée
              </b-form-invalid-feedback>
            </div>
            <b-form-group
              label="Statut : "
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio-group
                id="btn-radios-2"
                v-model="newPlayer.status"
                :options="optionsStatus"
                :aria-describedby="ariaDescribedby"
                button-variant="outline-secondary"
                name="radio-btn-outline"
                buttons
              ></b-form-radio-group>
            </b-form-group>
            <div class="form-group">
              <label>Club :</label>
              <b-form-input type="text" class="form-control" v-model="newPlayer.club" :state="isClubValid"/>
              <b-form-invalid-feedback :state="isClubValid">
                Le nom du club de la joueuse doit être composé d'au moins 2 lettres
              </b-form-invalid-feedback>
            </div>
            <b-form-group
              label="Zone : "
              v-slot="{ ariaDescribedby3 }"
            >
              <b-form-radio-group
                id="btn-radios-3"
                v-model="newPlayer.area"
                :options="optionsArea"
                :aria-describedby="ariaDescribedby3"
                button-variant="outline-secondary"
                name="radio-btn-outline"
                buttons
              ></b-form-radio-group>
            </b-form-group>
            <b-form-group
              label="Pied fort : "
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio-group
                id="btn-radios-2"
                v-model="newPlayer.strongfoot"
                :options="optionsStrongfoot"
                :aria-describedby="ariaDescribedby"
                button-variant="outline-secondary"
                name="radio-btn-outline"
                buttons
              ></b-form-radio-group>
            </b-form-group>
            <div class="form-group">
              <label>Nationalité :</label>
              <b-form-input type="text" class="form-control" v-model="newPlayer.nationality"/>
            </div>
            <b-form-group
            label="Suivi :"
          >
            <b-form-textarea v-model="newPlayer.suivi" rows="6" max-rows="9"></b-form-textarea>
          </b-form-group>
            <div class="form-group">
              <b-button :to="{name:'ScoutingSearch'}" class="mr-2" variant="danger">Annuler</b-button>
              <input type="submit" class="btn btn-primary" :disabled="!isFormValid" value="Éditer joueuse" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { db } from "@/db";
import navigation from "@/components/NavBar_F.vue";
//import firebase from "firebase";

export default {
  data() {
    return {
      newPlayer: {
        suivi:"",
        status:null,
        area:null,
        positionList:[],
      },
      optionsPromos: [1996,1997,1998,1999,2000,2001,2002,2003,2004,2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015,2016,2017,2018,2019,2020],
      optionsStrongfoot: ['G', 'D', 'LES 2'],
      optionsStatus:['A REVOIR','A SUIVRE','SIGNATURE SFC'],
      optionsArea:['Romandie','Suisse alémanique','France voisine', 'EU','Autre'],
      optionsPositions: ['GK','Lat. D', 'Lat. G', 'Def. Axe', 'Mil. DEF', 'Mil. OFF.', 'Att. D', 'Att. G', 'Att. Axe'],
    };
  },
  firebase() {
    return {
      player: db.ref("scouting-players-female").child(this.$route.params.scoutingPlayerId),
    };
  },
  components: {
    navigation
  },
  created() {
    /*if (!this.$store.state.currentUser.isScout) {
      this.$router.push({ name: "Home" });
    }*/
    let player = this.player; //this.teamsObj[this.$route.params.id];
    this.newPlayer = {
      firstname: player.firstname,
      lastname: player.lastname,
      position: player.position,
      promo: player.promo, 
      //status: player.status,
      club: player.club,
      //area: player.area,
      //strongfoot: player.strongfoot,
      nationality: player.nationality,
      //suivi: player.suivi,
    };
    if(player.positionList){
      this.newPlayer.positionList = player.positionList;
    }
    if(player.status){
      this.newPlayer.status = player.status;
    }
    if(player.strongfoot){
      this.newPlayer.strongfoot = player.strongfoot;
    }
    if(player.area){
      this.newPlayer.area = player.area;
    }
    if(player.suivi){
      this.newPlayer.suivi = player.suivi;
    }
    console.log(this.newPlayer);
  },
  computed:{
    isFirstnameValid(){
      return this.newPlayer.firstname.length >= 3 ? true : false; 
    },
    isLastnameValid(){
      return this.newPlayer.lastname.length >= 3 ? true : false; 
    },
    isPositionValid(){
      return this.newPlayer.positionList && this.newPlayer.positionList.length>=1 ? true : false; 
    },
    isPromoValid(){
      return this.newPlayer.promo >=1996 && this.newPlayer.promo<=2020 ? true : false; 
    },
    isClubValid(){
      return this.newPlayer.club.length >= 2 ? true : false; 
    },
    isFormValid(){
      return this.isFirstnameValid && this.isLastnameValid && this.isPositionValid && this.isPromoValid && this.isClubValid ? true : false; 
    }
  },
  methods: {
    updatePlayer() {
       /**
       * ADD TEST IF route param teamID is a team of the current user !! 
       */
      console.log(this.newPlayer);
      //this.newTeam.club = this.clubs[this.newTeam.club];
      console.log(this.$route.params.id);
      this.newPlayer.position = null;
      db.ref("scouting-players-female").child(this.$route.params.scoutingPlayerId).set(this.newPlayer);
      this.$router.push({name: "ScoutingSearch_F"});
    }
  }
};
</script>