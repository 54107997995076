<template>
  <section>
    <navigation></navigation>
    <jumbotron  v-bind:pageName="'Membres'" v-bind:filterText="'Membre'" v-bind:filterButtons="optionsRoleFilter" @setNewFilter="setNewFilter"></jumbotron>

    <div class="container mainView">
      <div class="my-2">{{ users.length }} membres</div>
      <b-table
        show-empty
        small
        :items="users"
        :fields="fields"
        :filter="filter"
        :filterIncludedFields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
        striped
        hover
      >
        <template v-slot:cell(actions)="row">
          <router-link :to="{ name: 'UserEdit', params: {id: row.item['.key']} }">
            <b-icon icon="pencil" class="rounded bg-light p-1" variant="dark" font-scale="1.5"></b-icon>
          </router-link>
        </template>

        <template v-slot:cell(name)="row">{{row.item.displayName}}</template>

        <template v-slot:cell(email)="row">{{row.item.email}}</template>
        <template v-slot:cell(lastActivity)="row">{{ moment(row.item.lastActivity).format('Do MMMM à H:mm')}}</template>
        <template v-slot:cell(role)="row">{{displayRole(row.item['.key'])}}</template>
        <template v-slot:cell(area)="row">{{displayArea(row.item['.key'])}}</template>
      </b-table>
    </div>
  </section>
</template>

<script>
import { db } from "@/db";
import navigation from "@/components/NavBar.vue";
import jumbotron from "@/components/JumbotronPage.vue";

export default {
  data() {
    return {
      users: [],
      scoutingRoles: [],
      fields: [
        { key: "actions", label: "Actions" },
        { key: "name", label: "Membre", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "lastActivity", label: "Activité", sortable: true },
        { key: "role", label: "Role", sortable: true },
        { key: "area", label: "Zone", sortable: true },
      ],
      totalRows: 1,
      sortBy: "lastActivity",
      sortDesc: true,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      optionsRoleFilter: ['ADMIN','TOPMANAGER_F','JUN_MANAGER_F', 'SCOUT_F','TOPMANAGER','CLUBMANAGER', 'SCOUT'],
    };
  },
  components: {
    navigation,
    jumbotron
  },
  firebase() {
    return {
      users: db.ref("users"),
      scoutingRoles: db.ref("scouting-role"),
    };
  },
  created() {
    if(!this.$store.state.currentUser || this.$store.state.currentUser.role!='ADMIN'){
      this.$router.push({ name: 'Home'});
    }
  },
  methods: {
    setNewFilter(filter){
      this.filter = filter;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      //this.currentPage = 1
    },
    displayRole(userId) {
      var role = "";
      var scoutingRole = this.scoutingRoles.find(obj => {
        return obj[".key"] === userId;
      });
      if(scoutingRole){
        return scoutingRole.role;
      }
      return role;
    },
    displayArea(userId) {
      var area = "";
      var scoutingRole = this.scoutingRoles.find(obj => {
        return obj[".key"] === userId;
      });
      if(scoutingRole){
        return scoutingRole.area;
      }
      return area;
    }
  },
  computed: {}
};
</script>
<style scoped>
</style>

