<template>
  <div class="container">
    <div class="mt-1">
      <label>
        <input
          type="file"
          id="files"
          ref="files"
          multiple
          v-on:change="handleFilesUpload()"
        />
      </label>
      <!--<progress max="100" :value.prop="uploadPercentage" class="w-100"></progress>-->
      <!--<b-button v-on:click="submitFiles()" variant="primary" class="float-right" size="sm">
        <b-icon icon="upload"></b-icon>< ! --&nbsp;Envoyer-- >
      </b-button>-->

      <b-alert v-model="showUploadSuccessAlert" dismissible variant="success" class="my-1">
        Le fichier a été uploadé correctement. 
      </b-alert>
      <b-alert v-model="showUploadWarningAlert" dismissible variant="warning" class="my-1">
        Erreur lors de l'upload du fichier.
      </b-alert>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  /*
      Defines the data used by the component
    */
  data() {
    return {
      files: [],
      uploadPercentage: 0,
      showUploadWarningAlert: false,
      showUploadSuccessAlert: false,
    };
  },
  props:["folder", "url"],
  methods: {
    submitFiles(){
      for( var i = 0; i < this.files.length; i++ ){
        let file = this.files[i];
        this.uploadFile(file, i);
        //formData.append('files[' + i + ']', file);
      }
    },
    /*
        Submits the file to the server
      */
    uploadFile(file, idx) {
      console.log(file.name)
      /*
                Initialize the form data
            */
      let formData = new FormData();

      /*
                Add the form data we need to submit
            */
      formData.append("file", file);
      formData.append("folder", this.folder);

      /*
          Make the request to the POST /single-file URL
        */
      axios
        .post("https://treat.gefvideo.ch/upload-"+this.url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
        .then(function (response) {
          this.showUploadSuccessAlert = true;
          console.log("SUCCESS!!");
          console.log(response);
          this.$emit("transferEnded", file);
          //create thumbnail
          /*
          var canvas = this.$el.querySelectorAll("canvas-element")[0];
          this.canvasWidth = this.file.videoWidth;
          this.canvasHeight = this.file.videoHeight;
          canvasCtx = canvas.getContext("2d");
          canvasCtx.drawImage(this.file, 0, 0, this.file.videoWidth, this.file.videoHeight);
          var dataURL = canvas.toDataURL("image/png");
          */


        }.bind(this))
        .catch(function (err) {
          this.showUploadWarningAlert = true;
          console.log("FAILURE!!");
          console.log(err);
        }.bind(this));
    },

    /*
        Handles a change on the file upload
      */
    handleFilesUpload() {
      this.files = this.$refs.files.files;
      this.submitFiles();
    },
  },
};
</script>