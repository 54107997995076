// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App'
import router from './router'
import store from './store'
//import * as firebase from 'firebase'
import firebase from 'firebase'
import 'firebase/analytics'
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue'
import moment from 'moment'
import { rtdbPlugin } from 'vuefire'
import axios from 'axios'

// Import the styles directly. (Or you could add them via script tags.)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueAutosuggest from "vue-autosuggest";
//import VueAnalytics from 'vue-analytics';

Vue.prototype.$http = axios

// Install BootstrapVue
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.config.productionTip = false
Vue.prototype.moment = moment

Vue.use(rtdbPlugin)


moment.locale('fr')
Vue.prototype.$moment = moment

Vue.use(VueAutosuggest);


Vue.prototype.$analytics = firebase.analytics();

/*
Vue.use(VueAnalytics, {
  id: 'G-GTN6D28267',
  router
})*/

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: {
    App
  },

  template: '<App/>'
})
